// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages, FormattedNumber, FormattedTime } from 'react-intl'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { APPLICATION } from '@console/constants/entities'

import Icon, { IconDevice, IconPlus } from '@ttn-lw/components/icon'
import Panel from '@ttn-lw/components/panel'
import Button from '@ttn-lw/components/button'
import Status from '@ttn-lw/components/status'

import Message from '@ttn-lw/lib/components/message'

import PropTypes from '@ttn-lw/lib/prop-types'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { setSearchOpen, setSearchScope } from '@console/store/actions/search'

import style from './total-end-devices-panel.tti.styl'

const m = defineMessages({
  noEndDevicesTitle: 'No end devices',
  noEndDevicesSubtitle: 'Your end devices will appear here.',
  unlimited: 'Unlimited',
  updateToday: 'Update: Today at {time}',
})

const TotalEndDevicesPanel = ({ totalCount, fullWidth, className }) => {
  const dispatch = useDispatch()

  const handleRegisterDeviceClick = React.useCallback(() => {
    dispatch(setSearchScope(APPLICATION))
    dispatch(setSearchOpen(true))
  }, [dispatch])

  return (
    <Panel
      title={fullWidth ? undefined : sharedMessages.totalEndDevices}
      icon={IconDevice}
      className={classnames(className, style.panel, { 'w-full': fullWidth })}
    >
      {totalCount !== 0 ? (
        fullWidth ? (
          <div className="d-flex direction-column j-between h-full">
            <div className="d-flex al-center j-start gap-cs-s">
              <Icon icon={IconDevice} className={classnames(style.fullWidthIcon)} />
              <span className="d-flex gap-cs-xs c-text-neutral-heavy fw-bold fs-xxl">
                <FormattedNumber value={totalCount} />
                <Status status="green" pulse className="d-flex al-center mt-cs-xs">
                  <Message content={sharedMessages.totalEndDevices} className="fs-l fw-bolder" />
                </Status>
              </span>
            </div>
            <Message
              className="c-text-neutral-light"
              content={m.updateToday}
              values={{
                time: (
                  <FormattedTime
                    value={new Date()}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                ),
              }}
            />
          </div>
        ) : (
          <div
            className={classnames(
              style.countDiv,
              'd-flex xl:al-center xl:j-center xl:direction-column al-end j-between direction-row gap-cs-xs h-full',
            )}
          >
            <Status status="green" pulse className="d-flex al-center">
              <span
                className={classnames(style.totalNumber, 'c-text-neutral-heavy fw-bold fs-xl4')}
              >
                <FormattedNumber value={totalCount} />
              </span>
            </Status>
            <Message
              className="c-text-neutral-light"
              content={m.updateToday}
              values={{
                time: (
                  <FormattedTime
                    value={new Date()}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                ),
              }}
            />
          </div>
        )
      ) : (
        <div className={classnames(style.content, { [style.contentFullWidth]: fullWidth })}>
          {fullWidth && <Icon icon={IconDevice} className={classnames(style.fullWidthIcon)} />}
          <Message
            className="c-text-neutral-heavy fw-bold fs-l text-center"
            content={m.noEndDevicesTitle}
          />
          <Message
            className={classnames('c-text-neutral-light fs-m text-center', {
              'mb-cs-l': !fullWidth,
            })}
            content={m.noEndDevicesSubtitle}
          />

          <Button
            primary
            message={sharedMessages.addEndDevice}
            icon={IconPlus}
            onClick={handleRegisterDeviceClick}
          />
        </div>
      )}
    </Panel>
  )
}

TotalEndDevicesPanel.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

TotalEndDevicesPanel.defaultProps = {
  className: undefined,
  fullWidth: false,
}

export default TotalEndDevicesPanel
