// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { APPLICATION, GATEWAY } from '@console/constants/entities'

import { IconApplication, IconGateway } from '@ttn-lw/components/icon'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import ShortcutPanel from '@console/containers/shortcut-panel'
import NocMetricsPanel from '@console/containers/noc-metrics-panel/index.tti'
import TotalEndDevicesPanel from '@console/containers/total-end-devices-panel/index.tti'

import { subtractDays } from '@ttn-lw/lib/date-utils'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectNocConfigEnabled, selectNocConfigUrl } from '@ttn-lw/lib/selectors/env'

import { checkFromState, mayConfigureTenantSettings } from '@console/lib/feature-checks'

import {
  getNocActiveApplications,
  getNocConnectedGateways,
} from '@console/store/actions/network-operations-center.tti'
import { getRegistryTotals } from '@console/store/actions/tenant.tti'

import {
  selectNocActiveApplicationsData,
  selectNocConnectedGatewaysData,
} from '@console/store/selectors/network-operations-center.tti'
import { selectRegistryTotals } from '@console/store/selectors/tenant.tti'
import { selectUserIsAdmin } from '@console/store/selectors/logout'

import style from './noc-panels.styl'

const NocPanels = ({ showRandomValues }) => {
  const isAdmin = useSelector(selectUserIsAdmin)
  const isNocEnabled = selectNocConfigEnabled() && selectNocConfigUrl() !== ''
  const registryTotals = useSelector(selectRegistryTotals)
  const totalGateways = !showRandomValues ? (registryTotals?.gateways ?? 0) : 10
  const totalApplications = !showRandomValues ? (registryTotals?.applications ?? 0) : 100
  const totalEndDevices = !showRandomValues ? (registryTotals?.end_devices ?? 0) : 1000

  const connectedGatewaysData = useSelector(selectNocConnectedGatewaysData)
  const activeApplicationsData = useSelector(selectNocActiveApplicationsData)

  const from = subtractDays(new Date(), 30).toISOString()
  const to = new Date().toISOString()
  const bucketInterval = '300s'

  const requestActions = []

  const showTenantSettings = useSelector(state => checkFromState(mayConfigureTenantSettings, state))
  if (!showRandomValues && showTenantSettings) {
    requestActions.push(getRegistryTotals())
  }

  return (
    <RequireRequest requestAction={requestActions} handleErrors={false}>
      {!isNocEnabled && showTenantSettings ? (
        <>
          <div className={classNames('item-12', { 'md-lg:item-6': isAdmin })}>
            <ShortcutPanel mobile={!isAdmin} />
          </div>
          {isAdmin && (
            <div className="item-12 md-lg:item-6">
              <TotalEndDevicesPanel fullWidth totalCount={totalEndDevices} />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={classNames('item-12', {
              [style.shortcutOverviewPanel]: showTenantSettings && isAdmin,
            })}
          >
            <ShortcutPanel mobile />
          </div>
          {showTenantSettings && isAdmin && (
            <div className="item-12 d-flex direction-column gap-ls-s md-lg:item-4">
              <ShortcutPanel className="h-full xl:d-none" />
              <TotalEndDevicesPanel totalCount={totalEndDevices} />
            </div>
          )}
          <NocMetricsPanel
            className={classNames('item-12', {
              'md-lg:item-6': !(showTenantSettings && isAdmin),
              'md-lg:item-4': showTenantSettings && isAdmin,
            })}
            title={sharedMessages.activeApplications}
            icon={IconApplication}
            fetchData={() => getNocActiveApplications(from, to, bucketInterval)}
            data={activeApplicationsData}
            totalCount={totalApplications}
            showRandomValues={showRandomValues}
            entity={APPLICATION}
            entityPath="/applications"
          />
          <NocMetricsPanel
            className={classNames('item-12', {
              'md-lg:item-6': !(showTenantSettings && isAdmin),
              'md-lg:item-4': showTenantSettings && isAdmin,
            })}
            title={sharedMessages.connectedGateways}
            icon={IconGateway}
            fetchData={() => getNocConnectedGateways(from, to, bucketInterval)}
            data={connectedGatewaysData}
            totalCount={totalGateways}
            showRandomValues={showRandomValues}
            entity={GATEWAY}
            entityPath="/gateways"
          />
        </>
      )}
    </RequireRequest>
  )
}

NocPanels.propTypes = {
  showRandomValues: PropTypes.bool,
}

NocPanels.defaultProps = {
  showRandomValues: false,
}

export default NocPanels
