// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'

import NotificationsDashboardPanel from '@console/containers/notifications-dashboard-panel'
import DocumentationDashboardPanel from '@console/containers/documentation-dashboard-panel'
import TopEntitiesDashboardPanel from '@console/containers/top-entities-dashboard-panel'
import NocPanels from '@console/containers/noc-panels/index.tti'
import LatestNewsPanel from '@console/containers/latest-news-panel/index.tti'
import StarterKit from '@console/containers/starter-kit-banner'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import {
  selectPluginTTSCloudEnabled,
  selectPluginTTSSandboxEnabled,
} from '@ttn-lw/lib/selectors/env'

const isLatestNewsPanelVisible = selectPluginTTSSandboxEnabled() || selectPluginTTSCloudEnabled()

const Overview = () => {
  useBreadcrumbs('overview.dashboard', <Breadcrumb path="/" content={sharedMessages.dashboard} />)

  return (
    <>
      <StarterKit className="d-none md-lg:d-flex" />
      <div className="container container--xl grid p-ls-s gap-ls-s md:p-cs-xs md:gap-cs-xs">
        <StarterKit className="d-flex md-lg:d-none" />
        <NocPanels />
        <div className="item-12 xl:item-6 md-lg:item-6">
          <TopEntitiesDashboardPanel />
        </div>
        <div className="item-12 xl:item-6 md-lg:item-6">
          <NotificationsDashboardPanel />
        </div>

        {isLatestNewsPanelVisible && (
          <div className="item-12 xl:item-6 md-lg:item-6">
            <LatestNewsPanel />
          </div>
        )}
        <div className="item-12 xl:item-6 md-lg:item-6">
          <DocumentationDashboardPanel />
        </div>
      </div>
    </>
  )
}

export default Overview
