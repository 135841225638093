// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import log from '../log'

const additionalConfig = JSON.parse(process.env.ADDITIONAL_CONFIG || '{}')

if (process.env.ADDITIONAL_CONFIG !== '{}') {
  log('Additional config loaded', additionalConfig)
}

export const configSelector = () => ({
  ...window.__ttn_config__,
  ...additionalConfig,
})

export const selectApplicationRootPath = () => configSelector().APP_ROOT

export const selectAssetsRootPath = () => configSelector().ASSETS_ROOT

export const selectBrandingRootPath = () => configSelector().BRANDING_ROOT

export const selectBrandingText = () => configSelector().BRANDING_TEXT

export const selectBrandingClusterID = () => configSelector().BRANDING_CLUSTER_ID

export const selectSlaApplies = () => configSelector().SLA_APPLIES

export const selectSlaInformationUrl = () => configSelector().SLA_INFORMATION_URL

export const selectFairUsePolicyInformationUrl = () =>
  configSelector().FAIR_USE_POLICY_INFORMATION_URL

export const selectSupportPlanApplies = () => configSelector().SUPPORT_PLAN_APPLIES

export const selectSupportPlanInformationUrl = () => configSelector().SUPPORT_PLAN_INFORMATION_URL

export const selectApplicationConfig = () => configSelector().APP_CONFIG

export const selectExperimentalFeatures = () => configSelector().EXPERIMENTAL_FEATURES

export const selectApplicationSiteName = () => configSelector().SITE_NAME

export const selectApplicationSiteTitle = () => configSelector().SITE_TITLE

export const selectApplicationSiteSubTitle = () => configSelector().SITE_SUB_TITLE

export const selectSentryDsnConfig = () => configSelector().SENTRY_DSN

export const selectDevEUIConfig = () => ({
  devEUIIssuingEnabled: selectApplicationConfig().dev_eui_issuing_enabled,
  applicationLimit: selectApplicationConfig().dev_eui_application_limit,
})

export const selectNocConfig = () => selectStackConfig().noc
export const selectNocConfigEnabled = () => selectNocConfig().enabled
export const selectNocConfigUrl = () => selectNocConfig().base_url
export const selectNocUrl = ({ appId, devId, gtwId, extended = false } = {}) => {
  let nocUrl = selectApplicationConfig().noc_url
  const access = extended ? 'extended' : 'basic'
  if (!nocUrl) {
    return undefined
  }
  if (appId) {
    if (!extended) {
      return undefined
    }
    if (devId) {
      nocUrl = `${nocUrl}/d/extended-ttsenddevice/end-device-details?var-application_id=${appId}&var-end_device_id=${devId}`
    } else {
      nocUrl = `${nocUrl}/d/extended-ttsapplication/application-details?var-application_id=${appId}`
    }
  } else if (gtwId) {
    nocUrl = `${nocUrl}/d/${access}-ttsgateway/gateway-details?var-gateway_id=${gtwId}`
  }

  return nocUrl
}

export const selectArsConfig = () => selectStackConfig().ars
export const selectArsEnabled = () => selectArsConfig().enabled

export const selectCSRFToken = () => configSelector().CSRF_TOKEN

export const selectStackConfig = () => selectApplicationConfig().stack_config

export const selectGsConfig = () => selectStackConfig().gs
export const selectGsEnabled = () => selectGsConfig().enabled

export const selectIsConfig = () => selectStackConfig().is
export const selectIsEnabled = () => selectIsConfig().enabled

export const selectNsConfig = () => selectStackConfig().ns
export const selectNsEnabled = () => selectNsConfig().enabled

export const selectJsConfig = () => selectStackConfig().js
export const selectJsEnabled = () => selectJsConfig().enabled

export const selectAsConfig = () => selectStackConfig().as
export const selectAsEnabled = () => selectAsConfig().enabled

export const selectGcsConfig = () => selectStackConfig().gcs
export const selectGcsEnabled = () => selectGcsConfig().enabled

export const selectLanguageConfig = () => selectApplicationConfig().language

export const selectSupportLinkConfig = () => selectApplicationConfig().support_link

export const selectDocumentationUrlConfig = () => selectApplicationConfig().documentation_base_url

export const selectPageStatusBaseUrlConfig = () => selectApplicationConfig().status_page_base_url

export const selectEnableUserRegistration = () => selectApplicationConfig().enable_user_registration

export const selectPageData = () => configSelector().PAGE_DATA

export const selectTTSLogo = () => `${selectAssetsRootPath()}/logo.svg`

export const selectBrandingLogo = () =>
  selectBrandingRootPath() !== selectAssetsRootPath()
    ? `${selectBrandingRootPath()}/logo.svg`
    : undefined

export const selectTTSStandardLogo = () => `${selectAssetsRootPath()}/logo-standard.svg`

export const selectTTSDiscoveryLogo = () => `${selectAssetsRootPath()}/logo-discovery.svg`

export const selectTTSPlusLogo = () => `${selectAssetsRootPath()}/logo-plus.svg`

export const selectTTSCloudLogo = () => `${selectAssetsRootPath()}/logo-cloud.svg`

export const selectTTSSandboxLogo = () => `${selectAssetsRootPath()}/logo-sandbox.svg`

export const selectTTNLogo = () => `${selectAssetsRootPath()}/logo-ttn-horizontal.svg`

export const selectTTSCloudMiniLogo = () => `${selectAssetsRootPath()}/tts-cloud-logo-icon.svg`

export const selectTTSMiniLogo = () => `${selectAssetsRootPath()}/tts-logo-icon.svg`

export const selectClusterPickerUrl = () => configSelector().CLUSTER_PICKER_URL

export const selectOwnTenantId = () => configSelector().TENANT_ID

export const selectTenantDecoratedClusterPickerUrl = () => {
  let clusterPickerUrl = selectClusterPickerUrl()
  const tenantId = selectOwnTenantId()
  const isMultiTenancy = selectIsMultiTenancy()
  if (clusterPickerUrl && tenantId && isMultiTenancy) {
    try {
      const clusterPickerUrlInstance = new URL(selectClusterPickerUrl())
      clusterPickerUrlInstance.hostname = `${tenantId}.${clusterPickerUrlInstance.hostname}`
      clusterPickerUrl = clusterPickerUrlInstance.toString()
    } catch {
      // Disregard invalid URLs.
    }
  }

  return clusterPickerUrl
}

export const selectIsMultiTenancy = () => configSelector().MULTI_TENANCY

export const selectPluginsConfig = () => selectApplicationConfig().plugins
export const selectPluginTTSCloud = () => selectPluginsConfig().tts_cloud
export const selectPluginTTSCloudEnabled = () => Boolean(selectPluginTTSCloud()?.enabled)
export const selectPluginTTSCloudNewsApi = () => selectPluginTTSCloud()?.news_api_url
export const selectPluginTTSSandbox = () => selectPluginsConfig().tts_sandbox
export const selectPluginTTSSandboxEnabled = () => Boolean(selectPluginTTSSandbox()?.enabled)
export const selectPluginTTSSandboxNewsApi = () => selectPluginTTSSandbox()?.news_api_url
export const selectPluginTTSStarterUser = () => selectPluginsConfig().tts_starter_user
export const selectPluginTTSStarterUserEnabled = () =>
  Boolean(selectPluginTTSStarterUser()?.enabled)
