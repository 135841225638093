// Copyright © 2025 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { defineMessages } from 'react-intl'

import LAYOUT from '@ttn-lw/constants/layout'
import lorawanStarterKit from '@assets/misc/lorawan-starter-kit.svg'
import lorawanStarterKitMobile from '@assets/misc/starter-kit-visual-mobile.png'

import { IconArrowRight, IconX } from '@ttn-lw/components/icon'
import Button from '@ttn-lw/components/button'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'

import {
  selectPluginTTSStarterUser,
  selectPluginTTSStarterUserEnabled,
} from '@ttn-lw/lib/selectors/env'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import PropTypes from '@ttn-lw/lib/prop-types'

import { updateUser } from '@console/store/actions/user'

import { selectConsolePreferences } from '@console/store/selectors/user-preferences'
import { selectUser } from '@console/store/selectors/logout'
import { selectDevicesTotalCount } from '@console/store/selectors/devices'
import { selectGatewaysTotalCount } from '@console/store/selectors/gateways'

import style from './starter-kit-banner.styl'

const starterUserPlugin = selectPluginTTSStarterUser()
const isStarterUserPluginEnabled = selectPluginTTSStarterUserEnabled()

const m = defineMessages({
  getStarted: 'Get started with LoRaWAN in minutes!',
  setup:
    'Our Starter Kit for LoRaWAN makes setup effortless with quick device onboarding and seamless gateway integration, so you can focus on building solutions, not setup. Perfect for those looking for a fresh start!',
  buttonGetStarterKit: 'Get your Starter Kit for LoRaWAN',
  buttonGetStarterKitMobile: 'Get your Starter Kit for LoRaWAN →',
})

const StarterKit = ({ className }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const consolePreferences = useSelector(state => selectConsolePreferences(state))
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= LAYOUT.BREAKPOINTS.S)
  const tutorialsSeen = consolePreferences.tutorials?.seen || []
  const seen = tutorialsSeen.includes('TUTORIAL_LORAWAN_STARTER_USER')
  const pluginDeviceThreshold = starterUserPlugin?.device_threshold
  const pluginGatewayThreshold = starterUserPlugin?.gateway_threshold
  const registeredDevices = useSelector(selectDevicesTotalCount)
  const registeredGateways = useSelector(selectGatewaysTotalCount)
  const isStarterUser =
    pluginDeviceThreshold >= (registeredDevices || 0) &&
    pluginGatewayThreshold >= (registeredGateways || 0)
  const starterKitUrl = starterUserPlugin?.starter_kit_url

  // Add a resize handler to detect mobile experiences.
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= LAYOUT.BREAKPOINTS.S) {
        setIsSmallScreen(true)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const setTutorialSeen = useCallback(async () => {
    const patch = {
      console_preferences: {
        tutorials: {
          seen: [...tutorialsSeen, 'TUTORIAL_LORAWAN_STARTER_USER'],
        },
      },
    }

    await dispatch(attachPromise(updateUser({ id: user.ids.user_id, patch })))
  }, [dispatch, tutorialsSeen, user.ids.user_id])

  if (!isStarterUserPluginEnabled || seen || !isStarterUser) {
    return null
  }

  return (
    <div className={classNames(style.starterKitBanner, 'item-12', className)}>
      <div className="d-flex al-center gap-cs-m">
        <img
          className={style.starterKitBannerImage}
          src={isSmallScreen ? lorawanStarterKitMobile : lorawanStarterKit}
          alt="lorawan-starter-kit"
        />
        <div>
          <Message content={m.getStarted} component="h3" className={style.starterKitBannerTitle} />
          <Message
            content={m.setup}
            component="p"
            className="mt-0 mb-cs-m lh-xs3 c-text-neutral-semilight md-lg:d-none"
          />
          <Button.AnchorLink
            primary
            href={starterKitUrl}
            message={m.buttonGetStarterKit}
            target="_blank"
            external
            className="md-lg:d-none"
          />
          <Link.Anchor
            primary
            href={starterKitUrl}
            target="_blank"
            className="d-none al-center gap-cs-xs md-lg:d-flex lh-1"
            icon={IconArrowRight}
          >
            <Message content={m.buttonGetStarterKitMobile} />
          </Link.Anchor>
        </div>
      </div>
      <Button naked icon={IconX} onClick={setTutorialSeen} />
    </div>
  )
}

StarterKit.propTypes = {
  className: PropTypes.string,
}

StarterKit.defaultProps = {
  className: undefined,
}

export default StarterKit
