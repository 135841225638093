// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import classnames from 'classnames'

import Icon from '@ttn-lw/components/icon'
import Link from '@ttn-lw/components/link'
import Tooltip from '@ttn-lw/components/tooltip'

import Message from '@ttn-lw/lib/components/message'

import PropTypes from '@ttn-lw/lib/prop-types'

import style from './shortcut-item.styl'

const ShortcutItem = ({ icon, link, action, title, className, mobile }) =>
  action ? (
    <Tooltip content={<Message content={title} />} delay={0}>
      <button
        onClick={action}
        className={classnames(style.shortcut, className, { [style.shortcutPanel]: !mobile })}
      >
        <Icon icon={icon} className={style.icon} size={25} />
        {mobile && <Message content={title} className="lg-xl:d-none" />}
      </button>
    </Tooltip>
  ) : (
    <Tooltip content={<Message content={title} />} delay={0}>
      <Link
        to={link}
        className={classnames(style.shortcut, className, { [style.shortcutPanel]: !mobile })}
      >
        <Icon icon={icon} className={style.icon} size={25} />
        {mobile && <Message content={title} className="lg-xl:d-none" />}
      </Link>
    </Tooltip>
  )

ShortcutItem.propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.icon.isRequired,
  link: PropTypes.string,
  mobile: PropTypes.bool,
  title: PropTypes.message.isRequired,
}

ShortcutItem.defaultProps = {
  className: undefined,
  action: undefined,
  link: undefined,
  mobile: false,
}

export default ShortcutItem
